;(function($) {

	"use strict";

	var animationEndEvent = 'webkitAnimationEnd oAnimationEnd MSAnimationEnd animationend';
	var transitionEndEvent = 'webkitTransitionEnd oTransitionEnd MSTransitionEnd transitionend';
	var lazyLoadRefreshEvent = 'lazyload.refresh';
	var skrollrOptions = {
		smoothScrolling: false,
		forceHeight:     false,
		mobileCheck:     function() {
			return false;
		}
	};

	window.lazySizesConfig.throttleDelay = 100;
	window.lazySizesConfig.preloadAfterLoad = true;

	$(document).ready(function() {

		//==================================
		//	!---------- GLOBAL ----------
		//==================================

		// ---------- Replace hover rules by active on touchscreens ----------

		if(!!('ontouchstart' in window) || !!('msmaxtouchpoints' in window.navigator)) {
			for(var sheetI = document.styleSheets.length - 1; sheetI >= 0; sheetI--) {
				var sheet = document.styleSheets[sheetI];
				if(sheet.cssRules) {
					for(var ruleI = sheet.cssRules.length - 1; ruleI >= 0; ruleI--) {
						var rule = sheet.cssRules[ruleI];

						if(rule.selectorText) {
							rule.selectorText = rule.selectorText.replace(':hover', ':active');
						}
					}
				}
			}
		}

		// ---------- Menu ----------

		$('.mobile-menu-overlay').click(function(e) {
			e.preventDefault();
			$('body').removeClass('mobile-menu-open');
		});

		$('.mobile-menu-toggle').click(function(e) {
			e.preventDefault();
			$('body').toggleClass('mobile-menu-open');
			$('body').scrollTop(0);
		});

		if($('body').hasClass('home')) {

			$(window).throttle('scroll', function() {
				if($(window).scrollTop() < window.innerHeight * 0.5) {
					$('.header-menu-wrapper .dropdown-menu').addClass('dropup');
				}
				else {
					$('.header-menu-wrapper .dropdown-menu').removeClass('dropup');
				}
			}, 100);

			$(window).trigger('scroll');
		}

		// ---------- Links ----------

		$('a[href="#"], .void-link, [data-lity]').click(function(e) {
			e.preventDefault();
		});

		$('[data-scroll-to]').click(function(e) {
			e.preventDefault();

			var self = $(this);
			var target = (self.is('a')) ? $(self.attr('href')) : $(self.data('scroll-to'));

			if(target.length) {
				var offset = (target.offset().top > 320) ? target.offset().top - 80 : 0;
				$('html, body').stop().animate({
					scrollTop: offset
				}, 1000);
			}
		});

		$('.home-video-link').click(function() {

			var self = $(this);
			var target = $(self.data('video'));

			$('.home-video').each(function() {

				var video = $(this);

				if(video.hasClass('active')) {
					video.removeClass('show active');
					video.find('iframe').removeClass('lazyloaded').addClass('lazyload');
					video.html(video.html());
				}
			});

			if(target.length) {
				target.addClass('active');

				setTimeout(function() {
					target.addClass('show');
				});
			}
		});

		// ---------- Animsition ----------

		$('.main-wrapper').animsition({
			inClass:     'fade-in',
			outClass:    'fade-out',
			inDuration:  600,
			outDuration: 400,
			linkElement: 'a:not([target="_blank"]):not([href^="#"]):not([href^="mailto:"]):not([href^="tel:"]):not(.void-link):not([data-lity])',
		});

		// ---------- Carousels ----------

		$('.masonry').each(function() {
			var self = $(this);

			self.isotope({
				masonry:      {
					percentPosition: true
				},
				itemSelector: ".single-column"
			})
		});

		$('.clients-section .carousel').each(function() {

			var self = $(this);

			var settings = {
				arrows:         true,
				infinite:       false,
				centerMode:     false,
				slidesToShow:   6,
				slidesToScroll: 6,
				responsive:     [
					{
						breakpoint: 1024,
						settings:   {
							slidesToShow:   4,
							slidesToScroll: 4
						}
					},
					{
						breakpoint: 480,
						settings:   {
							slidesToShow:   2,
							slidesToScroll: 2
						}
					}
				]
			};

			self.on('init reInit beforeChange afterChange', function() {
				$(window).trigger(lazyLoadRefreshEvent);
			});

			if(!self.hasClass('slick-initialized')) {
				self.slick(settings);
			}
		});

		$('.carousel').each(function() {

			var self = $(this);

			var settings = {
				arrows:         false,
				infinite:       false,
				centerMode:     false,
				slidesToShow:   4,
				slidesToScroll: 4,
				responsive:     [
					{
						breakpoint: 1024,
						settings:   {
							slidesToShow:   3,
							slidesToScroll: 3
						}
					},
					{
						breakpoint: 768,
						settings:   {
							slidesToShow:   2,
							slidesToScroll: 2
						}
					},
					{
						breakpoint: 480,
						settings:   {
							slidesToShow:   1,
							slidesToScroll: 1
						}
					}
				]
			};

			self.on('init reInit beforeChange afterChange', function() {
				$(window).trigger(lazyLoadRefreshEvent);
			});

			if(!self.hasClass('slick-initialized')) {
				self.slick(settings);
			}
		});

		$('.slideshow').each(function() {

			var self = $(this);

			var settings = {
				infinite:      true,
				autoplaySpeed: 3000,
				arrows:        false,
				autoplay:      true,
				speed:         2000,
				fade:          true,
			};

			self.on('init reInit beforeChange afterChange', function() {
				$(window).trigger(lazyLoadRefreshEvent);
			});

			if(!self.hasClass('slick-initialized')) {
				self.slick(settings);
			}
		});

		$('.gallery').each(function() {
			var self = $(this);

			self.lightGallery({
				download: false,
			});
		});

		// ---------- Skrollr ----------

		function skrollrInit() {
			skrollr.init(skrollrOptions);
		}

		$(window).debounce('resize', function() {
			var skrollrInstance = skrollr.get();

			if(window.innerWidth <= 767 && skrollrInstance !== undefined) {
				skrollrInstance.destroy();
			}
			else if(window.innerWidth > 767 && skrollrInstance === undefined) {
				skrollrInit();
			}
		}, 200);

		$(window).trigger('resize');

		$(window).throttle('scroll', function() {
			var finished = $('.skrollable-after.one-time');
			var skrollrInstance = skrollr.get();

			finished.each(function() {
				var self = $(this).removeClass('skrollable-after'),
				    data = {};

				$.each(this.attributes, function() {
					if(this.specified && this.name.indexOf('data-') === 0) {
						data[this.name] = true;
					}
				});

				for(var d in data) {
					self.removeAttr(d);
				}
			});

			if(finished.length) {
				skrollrInstance.destroy();
				skrollrInit();
			}
		}, 200);

		$(window).trigger('scroll');

		$(window).on('load', function() {

			$(window).trigger('resize');
			$(window).trigger('scroll');

		});

		$(window).throttle('scroll', function scrollHandler() {

			if($('.home-numbers-section').hasClass('skrollable-after')) {

				$('.home-numbers-section strong').each(function() {

					var self = $(this);

					var text = self.text();
					var number = parseFloat(text, 10);
					var decimalPlace = (text.indexOf('/5') > 0) ? 1 : 0;
					var decimalFactor = decimalPlace === 0 ? 1 : Math.pow(10, decimalPlace);

					self.prop('number', 0).animateNumber({
						number:     number * decimalFactor,
						numberStep: function(now, tween) {

							var flooredNumber = Math.floor(now) / decimalFactor,
							    target         = $(tween.elem);

							if(decimalPlace > 0) {
								// force decimal places even if they are 0
								flooredNumber = flooredNumber.toFixed(decimalPlace);
							}

							var finalText = (text.indexOf('/5') > 0) ? flooredNumber + '/5' : flooredNumber;

							target.text(finalText);
						}
					}, 5000);
				});

				$(window).off('scroll', scrollHandler);
			}

		}, 150);

		// ---------- LazyLoad ----------

		$(window).on(lazyLoadRefreshEvent, function() {

			window.lazySizes.loader.checkElems();
			window.lazySizes.autoSizer.checkElems();

		});

		$(window).trigger(lazyLoadRefreshEvent);

		// ---------- Vide ----------

		$(window).on('load', function() {

			$('.video-background').each(function() {
				var self = $(this);
				var video = self.data('video');
				var poster = self.data('poster');

				self.vide({
					mp4:    video,
					poster: poster
				}, {
					posterType: 'jpg'
				})
			});

		});

		// ---------- Disclaimer ----------

		$('.close-disclaimer').on('click touchstart', function(e) {
			e.preventDefault();

			Cookies.set('disclaimer', true, {
				expires: 365,
				path:    '/',
			});

			$('#disclaimer').fadeOut(750, function() {
				$(this).remove();
			});

			$('body').removeClass('disclaimer-open');
		});

	});

}(jQuery));
